export enum TipoAssociazioneEnum {
  AssociazioneGenerica = 'Associazione Generica',
  AssociazioneVolontariato = 'Associazione di Volontariato',
  AssociazionePromozioneSociale = 'Associazione di Promozione Sociale',
  AssociazioneSportivaDilettantistica = 'Associazione Sportiva Dilettantistica',
  Confraternita = 'Confraternita'
}
export const TipoAssociazione = [
  'Associazione Generica',
  'Associazione di Volontariato',
  'Associazione di Promozione Sociale',
  'Associazione Sportiva Dilettantistica',
  'Confraternita'
];
